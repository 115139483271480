import DashboardLayout from "@/pages/layouts/DashboardLayout";

let clientRoutes = {
  path: '/clients',
  component: DashboardLayout,
  redirect: '/clients/list',
  children: [
    {
      path: 'list',
      name: 'Client List',
      component: () => import(/* webpackChunkName: "clients" */  '@/pages/clients/ClientList'),
      meta: {
        permissionsCodes: ['clients/index','restricted_own/clients/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Client',
      component: () => import(/* webpackChunkName: "clients" */  '@/pages/clients/ClientForm'),
      meta: {
        permissionsCodes: ['clients/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Client',
      component: () => import(/* webpackChunkName: "clients" */  '@/pages/clients/ClientForm'),
      meta: {
        permissionsCodes: ['clients/update','restricted_own/clients/update'],
      }
    }
  ]
}

export default clientRoutes;
