import DashboardLayout from "@/pages/layouts/DashboardLayout";

let websiteClientRoutes = {
  path: '/website-clients',
  component: DashboardLayout,
  redirect: '/website-clients/list',
  children: [
    {
      path: 'list',
      name: 'Website Client List',
      component: () => import(/* webpackChunkName: "websiteClients" */  '@/pages/websiteClients/WebsiteClientList'),
      meta: {
        permissionsCodes: ['website-clients/index','restricted_own/website-clients/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Website Client',
      component: () => import(/* webpackChunkName: "websiteClients" */  '@/pages/websiteClients/WebsiteClientForm'),
      meta: {
        permissionsCodes: ['website-clients/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Website Client',
      component: () => import(/* webpackChunkName: "websiteClients" */  '@/pages/websiteClients/WebsiteClientForm'),
      meta: {
        permissionsCodes: ['website-clients/update','restricted_own/website-clients/update'],
      }
    }
  ]
}

export default websiteClientRoutes;
