<template>

  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>

    <side-bar>

      <user-menu></user-menu>

      <mobile-menu></mobile-menu>

      <template slot-scope="props" slot="links">
        <sidebar-item :link="{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/overview'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['requests/index'])"
                      :link="{name: 'Requests', icon: 'nc-icon nc-attach-87', path: '/requests/list'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['clients/index', 'restricted_own/clients/index'])"
                      :link="{name: 'Clients', icon: 'nc-icon nc-badge', path: '/clients/list'}">
        </sidebar-item>



        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['campaigns/index', 'restricted_own/campaigns/index'])"
                      :link="{name: 'Campaign', icon: 'nc-icon nc-notification-70', path: '/campaigns/list'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['projects/index', 'restricted_own/projects/index'])"
                      :link="{name: 'Projects', icon: 'nc-icon nc-icon nc-app', path: '/projects/list'}">
        </sidebar-item>

<!--        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['newsletter/index'])" :link="{name: 'Newsletter Subscribers', icon: 'nc-icon nc-email-83', path: '/newsletter'}">-->
<!--        </sidebar-item>-->
<!--        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['website-clients/index'])" :link="{name: 'Website Clients', icon: 'nc-icon nc-bag', path: '/website-clients'}">-->
<!--        </sidebar-item>-->
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['demo-requests/index'])" :link="{name: 'Demo Requests', icon: 'nc-icon nc-delivery-fast', path: '/demo-requests'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['contact-us/index'])" :link="{name: 'Contact Messages', icon: 'nc-icon nc-support-17', path: '/contact-us'}">
        </sidebar-item>


        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                                            (['pages/index', 'restricted_own/pages/index',
                                             'content-pages/create',
                                             'redirection-pages/create',
                                             'branches/index', 'restricted_own/branches/index'])"
                      :link="{name: 'Pages', icon: 'nc-icon nc-single-copy-04'}">

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['pages/index','restricted_own/pages/index'])"
                        :link="{name: 'Existing Pages', path: '/pages/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['content-pages/create'])"
                        :link="{name: 'Add Content Page', path: '/content-pages/create'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['redirection-pages/create'])"
                        :link="{name: 'Add Redirection Page', path: '/redirection-pages/create'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['branches/index','restricted_own/branches/index'])"
                        :link="{name: 'Branches', path: '/branches/list'}"></sidebar-item>

        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['panel-groups/index','panels/index',
                       'restricted_own/panel-groups/index','restricted_own/panels/index'])"
                      :link="{name: 'Panels And Banners', icon: 'nc-icon nc-album-2'}">

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['panels/index','restricted_own/panels/index'])"
                        :link="{name: 'Panels', path: '/panels/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['panel-groups/index','restricted_own/panel-groups/index'])"
                        :link="{name: 'Groups', path: '/panel-groups/list'}"></sidebar-item>

        </sidebar-item>



        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
        (['settings/index', 'restricted_own/settings/index',
        'mediaInfo/index', 'restricted_own/mediaInfo/index'])"
            :link="{name: 'Settings', icon: 'nc-icon nc-circle-09'}">

            <sidebar-item v-if="false"
                          :link="{name: 'Media Info', path: '/mediaInfo/list'}">
            </sidebar-item>
            <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['settings/index', 'restricted_own/settings/index'])"
                          :link="{name: 'Settings', path: '/settings/list'}">
            </sidebar-item>


        </sidebar-item>


        <!--</sidebar-item>-->

        <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')
                            // || $store.getters['auth/checkAccess']('permissions/index')
                            || $store.getters['auth/checkAccess']('users/index')"
                      :link="{name: 'Access Management', icon: 'nc-icon nc-circle-09'}">

          <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')"
                        :link="{name: 'Roles', path: '/roles/list'}"></sidebar-item>
          <sidebar-item v-if="false"
                        :link="{name: 'Permissions', path: '/permissions/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/checkAccess']('users/index')"
                        :link="{name: 'Users', path: '/users/list'}"></sidebar-item>

        </sidebar-item>





      </template>

    </side-bar>


    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"></dashboard-content>

      <content-footer></content-footer>
    </div>

  </div>
</template>
<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
import MobileMenu from './Extra/MobileMenu.vue'
import UserMenu from './Extra/UserMenu.vue'


export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    }
  }
}

</script>
