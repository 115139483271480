import DashboardLayout from "@/pages/layouts/DashboardLayout";

let campaignRoutes = {
  path: '/campaigns',
  component: DashboardLayout,
  redirect: '/campaigns/list',
  children: [
    {
      path: 'list',
      name: 'Campaign List',
      component: () => import(/* webpackChunkName: "campaigns" */  '@/pages/campaigns/CampaignList'),
      meta: {
        permissionsCodes: ['campaigns/index','restricted_own/campaigns/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Campaign',
      component: () => import(/* webpackChunkName: "campaigns" */  '@/pages/campaigns/CampaignForm'),
      meta: {
        permissionsCodes: ['campaigns/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Campaign',
      component: () => import(/* webpackChunkName: "campaigns" */  '@/pages/campaigns/CampaignForm'),
      meta: {
        permissionsCodes: ['campaigns/update','restricted_own/campaigns/update'],
      }
    },
    {
      path: 'add-email-template/:id',
      name: 'Att Email Template',
      component: () => import(/* webpackChunkName: "campaigns" */  '@/pages/campaigns/CampaignEmailTemplateForm'),
      meta: {
        permissionsCodes: ['campaigns/add-email-template','restricted_own/campaigns/add-email-template'],
      }
    }
  ]
}

export default campaignRoutes;
