var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{'nav-open': _vm.$sidebar.showSidebar}},[_c('notifications'),_c('side-bar',{scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/overview'}}}),(_vm.$store.getters['auth/haveOneOfPermissions'](['requests/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Requests', icon: 'nc-icon nc-attach-87', path: '/requests/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['clients/index', 'restricted_own/clients/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Clients', icon: 'nc-icon nc-badge', path: '/clients/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['campaigns/index', 'restricted_own/campaigns/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Campaign', icon: 'nc-icon nc-notification-70', path: '/campaigns/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['projects/index', 'restricted_own/projects/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Projects', icon: 'nc-icon nc-icon nc-app', path: '/projects/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['demo-requests/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Demo Requests', icon: 'nc-icon nc-delivery-fast', path: '/demo-requests'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['contact-us/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Contact Messages', icon: 'nc-icon nc-support-17', path: '/contact-us'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                                            (['pages/index', 'restricted_own/pages/index',
                                             'content-pages/create',
                                             'redirection-pages/create',
                                             'branches/index', 'restricted_own/branches/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Pages', icon: 'nc-icon nc-single-copy-04'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['pages/index','restricted_own/pages/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Existing Pages', path: '/pages/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['content-pages/create']))?_c('sidebar-item',{attrs:{"link":{name: 'Add Content Page', path: '/content-pages/create'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['redirection-pages/create']))?_c('sidebar-item',{attrs:{"link":{name: 'Add Redirection Page', path: '/redirection-pages/create'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['branches/index','restricted_own/branches/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Branches', path: '/branches/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                       (['panel-groups/index','panels/index',
                       'restricted_own/panel-groups/index','restricted_own/panels/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Panels And Banners', icon: 'nc-icon nc-album-2'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['panels/index','restricted_own/panels/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Panels', path: '/panels/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['panel-groups/index','restricted_own/panel-groups/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Groups', path: '/panel-groups/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
        (['settings/index', 'restricted_own/settings/index',
        'mediaInfo/index', 'restricted_own/mediaInfo/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Settings', icon: 'nc-icon nc-circle-09'}}},[(false)?_c('sidebar-item',{attrs:{"link":{name: 'Media Info', path: '/mediaInfo/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['settings/index', 'restricted_own/settings/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Settings', path: '/settings/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/checkAccess']('roles/index')
                            // || $store.getters['auth/checkAccess']('permissions/index')
                            || _vm.$store.getters['auth/checkAccess']('users/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Access Management', icon: 'nc-icon nc-circle-09'}}},[(_vm.$store.getters['auth/checkAccess']('roles/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Roles', path: '/roles/list'}}}):_vm._e(),(false)?_c('sidebar-item',{attrs:{"link":{name: 'Permissions', path: '/permissions/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('users/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Users', path: '/users/list'}}}):_vm._e()],1):_vm._e()]}}])},[_c('user-menu'),_c('mobile-menu')],1),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('dashboard-content',{nativeOn:{"click":function($event){return _vm.toggleSidebar($event)}}}),_c('content-footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }