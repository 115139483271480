import DashboardLayout from "@/pages/layouts/DashboardLayout";

let requestRoutes = {
  path: '/requests',
  component: DashboardLayout,
  redirect: '/requests/list',
  children: [
    {
      path: 'list',
      name: 'Request List',
      component:()=>import(/* webpackChunkName: "contact-us" */ '@/pages/requests/RequestList'),
      meta: {
        permissionsCodes: ['requests/index'],
      }
    },
    {
      path: 'show/:id',
      name: 'Request Details',
      component:()=>import(/* webpackChunkName: "contact-us" */ '@/pages/requests/RequestView'),
      meta: {
        permissionsCodes: ['requests/get'],
      }
    },
    {
      path: 'responses/:id',
      name: 'Request Response List',
      component:()=>import(/* webpackChunkName: "contact-us" */ '@/pages/requests/RequestResponseList'),
      meta: {
        permissionsCodes: ['request-responses/get'],
      }
    },
    {
      path: 'responses/send/:id',
      name: 'Send Response',
      component:()=>import(/* webpackChunkName: "contact-us" */ '@/pages/requests/SendResponseForm'),
      meta: {
        permissionsCodes: ['request-responses/send'],
      }
    },
  ]
}

export default requestRoutes;
