import DashboardLayout from "@/pages/layouts/DashboardLayout";

let demoRequestRoutes = {
  path: '/demo-requests',
  component: DashboardLayout,
  redirect: '/demo-requests/list',
  children: [
    {
      path: 'list',
      name: 'Demo Requests List',
      component:()=>import(/* webpackChunkName: "demo-requests" */ '@/pages/general/demoRequests/DemoRequestList'),
      meta: {
        permissionsCodes: ['demo-requests/index'],
      }
    },
    {
      path: 'show/:id',
      name: 'Demo Request Details',
      component:()=>import(/* webpackChunkName: "demo-requests" */ '@/pages/general/demoRequests/DemoRequestView'),
      meta: {
        permissionsCodes: ['demo-requests/get'],
      }
    },
  ]
}

export default demoRequestRoutes;
