import DashboardLayout from "@/pages/layouts/DashboardLayout";

let panelGroupsRoutes = {
  path: '/panel-groups',
  component: DashboardLayout,
  redirect: '/panel-groups/list',
  children: [
    {
      path: 'list',
      name: 'Group List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/panels/PanelsGroupList'),
      meta: {
        permissionsCodes: ['panel-groups/index', 'restricted_own/panel-groups/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Group',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/panels/PanelsGroupForm'),
      meta: {
        permissionsCodes: ['panel-groups/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Group',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/panels/PanelsGroupForm'),
      meta: {
        permissionsCodes: ['panel-groups/update', 'restricted_own/panel-groups/update'],
      }
    }
  ]
}

export default panelGroupsRoutes;
