<template>
  <div :class="filter.class">
    <div class="form-group has-label" >
      <label>{{filter.label}} </label>
      <el-select :multiple="filter.multiple" class="select-primary "
                 size="large"
                 v-model="filter.value"
      >
        <el-option v-for="option in filter.options"
                   class="select-primary"
                   :value="option.id"
                   :label="option.name"
                   :key="option.name">
        </el-option>
      </el-select>


    </div>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';

export default {
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
  },

  props: ["filter"],
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style>
.select-filter{
  margin: 5px;
}
</style>
